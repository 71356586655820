<template>
  <v-container fluid class="pa-0">
    <v-card flat class="pa-2">
      <v-form lazy-validation v-on:submit.prevent="" ref="form">
        <v-card-title>
          <span class="headline primary--text">{{ titulo }}</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="mr-2"
                ripple
                color="primary"
                icon="icon"
                @click="cancelar"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Voltar</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-expansion-panels flat id="patient-data">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pa-0">
                        <v-card outlined>
                          <v-card-subtitle v-bind="attrs" v-on="on" class="pa-2">
                            <div>Paciente: {{pacientePreAtendimento.nome}}</div>
                            <div>Telefone: {{pacientePreAtendimento.telefone}}</div>
                          </v-card-subtitle>
                        </v-card>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="expansion-panel-content">
                        <v-card outlined>
                          <v-card-subtitle class="pa-2">
                            <div>Unidade: {{pacientePreAtendimento.unidade.nome}}</div>
                            <div>E-mail: {{pacientePreAtendimento.email}}</div>
                            <div>Data de Nascimento: {{formatDataNascimento(pacientePreAtendimento.dataNascimento)}}</div>
                            <div>CPF: {{pacientePreAtendimento.cpf}}</div>
                            <div>RG: {{pacientePreAtendimento.rg}}</div>
                          </v-card-subtitle>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <span>Clique para mais detalhes</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <template>
                <v-card outlined>
                  <v-card-title>
                    <span class="primary--text"
                      >Registro de Contatos</span
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          ripple
                          color="primary"
                          @click="itemCreateEdit(registroContato)"
                          v-bind="attrs"
                          v-on="on"
                          v-show="showAdicionarContatoBtn"
                        >
                          <v-icon class="mr-2">mdi-plus</v-icon> Adicionar Contato
                        </v-btn>
                      </template>
                      <span>Adicionar Novo</span>
                    </v-tooltip>
                  </v-card-title>

                  <v-divider></v-divider>
 
                  <v-data-table
                    :items="registroPreAtendimento.registroContatos"
                    :headers="headersRegistroContato"
                    v-model="selectedRegistroContatos"
                    sort-by="dataCadastro"
                    selected-key="id"
                    show-select
                    dense
                  >

                    <tr slot="item.dataCadastro" slot-scope="props">
                      <span>{{ new Date(props.item.dataCadastro).toLocaleString('pt-BR') }}</span>
                    </tr>

                    <template v-slot:top>
                      <v-dialog v-model="dialog" max-width="520px" persistent>
                        <v-card>
                          <v-card-title>
                            <span class="headline primary--text">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text class="py-0">
                            <v-container>
                              <v-row no-gutters>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="registroContato.respostaContato"
                                    filled
                                    rounded
                                    label="Resposta Contato"
                                    item-text="descricao"
                                    item-value="id"
                                    return-object
                                    :items="respostaContatos"
                                    dense
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    v-model="registroContato.observacao"
                                    label="Observação"
                                    hide-details
                                    filled
                                    rounded
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions class="pt-0">
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1" text @click="cancelCreateEdit">
                              Cancelar
                            </v-btn>
                            <v-btn color="primary darken-1" text @click="confirmCreateEdit">
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog v-model="dialogDelete" max-width="520px" persistent>
                        <v-card>
                          <v-card-title class="headline error--text"
                            >Tem certeza que deseja excluir o
                            registro?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1" text @click="cancelDelete">
                              Cancelar
                            </v-btn>
                            <v-btn color="primary darken-1" text @click="confirmDelete">
                              Confirmar
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogStatusPendente" persistent  max-width="450">
                        <v-card>
                            <v-card-title>Atenção</v-card-title>
                            <v-card-text>Tem certeza que deseja alterar o atendimento para pendente?</v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text class="red--text darken-1" @click.native="cancelStatusPendente">Não</v-btn>
                            <v-btn text class="green--text darken-1" @click.native="confirmStatusPendente">Sim</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogStatusConfirmado" persistent  max-width="450">
                        <v-card>
                            <v-card-title>Atenção</v-card-title>
                            <v-card-text>Tem certeza que deseja confirmar o atendimento?</v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text class="red--text darken-1" @click.native="cancelStatusConfirmado">Não</v-btn>
                            <v-btn text class="green--text darken-1" @click.native="confirmStatusConfirmado">Sim</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogMotivoCancelamento" persistent  max-width="450">
                        <v-card>
                            <v-card-title>Motivo de cancelamento</v-card-title>
                            <v-container>
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    v-model="registroPreAtendimento.motivoCancelamento"
                                    filled
                                    rounded
                                    label="Motivo Cancelamento"
                                    item-text="descricao"
                                    item-value="id"
                                    return-object
                                    :items="motivosCancelamentos"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                            <v-card-text>Tem certeza que deseja cancelar o atendimento?</v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text class="red--text darken-1" @click.native="cancelMotivoCancelamento">Não</v-btn>
                            <v-btn text class="green--text darken-1" @click.native="confirmMotivoCancelamento">Sim</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-if="possuiPermissaoAdmin" slot="item.actions" slot-scope="props">
                      <v-icon small class="mr-2" @click="itemCreateEdit(props.item)" v-if="showAcoesEditarRegistroContatoContatoBtn">
                        mdi-pencil
                      </v-icon>
                      <v-icon small class="mr-2" @click="deleteItem(props.item)" v-if="showAcoesExcluirRegistroContatoContatoBtn">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Observação"
                name="observacao"
                hide-details
                filled
                rounded
                required
                v-model="registroPreAtendimento.observacao"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-5">
              <v-autocomplete
                v-model="registroPreAtendimento.medico"
                filled
                rounded
                clearable
                hide-details
                label="Médico"
                item-text="nomeExibicao"
                item-value="id"
                return-object
                :items="medicos"
              ></v-autocomplete>
            </v-col>
          </v-row>          
          <v-row> </v-row>
        </v-card-text>
        <v-card-actions class="pr-6">
          <v-row justify="end" class="mb-4">
            <v-btn dark class="mr-1 mb-1" width="250" color="orange" @click.stop="changeStatusPendenteComputed=false" v-if="showPendenteAtendimentoBtn">
              Atendimento Pendente
            </v-btn>
            <v-btn dark class="mr-1 mb-1" width="250" color="red" @click.stop="changeStatusCanceladoComputed=false" v-if="showCancelarAtendimentoBtn">
              Cancelar Atendimento
            </v-btn>
            <v-btn dark class="mr-1 mb-1" width="250" color="green" @click.stop="changeStatusConfirmadoComputed=false" v-if="showConfirmarAtendimentoBtn">
              Confirmar Atendimento
            </v-btn>
            <v-btn dark class="mr-1 mb-1" width="250" color="blue" v-if="showImprimirPosConsultaBtn" @click="visualizarImpressao" download :loading="loadingBtnVisualizarImpressao"> 
              Visualizar Impressão 
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-row>
        </v-card-actions>
        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import { possuiPermissao, isAdminMaster } from "../../seguranca";
import moment from 'moment'


export default {
  name: "registro-pre-atendimento-create-edit",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    idParametter:  {
      type: Number ,
      default: null
    },
    idAgendamento: {
      type: Number ,
      default: null
    },
    idPreAtendimento: {
      type: Number ,
      default: null
    },
    pacientePreAtendimento: {
      type: Object,
      default: null
    },
    agendamentoUnidadeId: {
      type: Number,
      default: null
    },
    agendamentoSelecionado:{
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loader: null,
      inativo: false,
      loadingBtnVisualizarImpressao: false,
      showConfirmarAtendimentoBtn: false,
      showCancelarAtendimentoBtn: false,
      showPendenteAtendimentoBtn: false,
      showImprimirPosConsultaBtn: false,
      showAdicionarContatoBtn: false,
      showAcoesEditarRegistroContatoContatoBtn: false,
      showAcoesExcluirRegistroContatoContatoBtn: false,
      registroPreAtendimento: {
        registroContatos: [],
        status: "PENDENTE",
        agendamento: {},
      },
      medicos: [],
      respostaContatos: [],
      motivosCancelamentos: [],
      oldStatus: null,
      registroContatos: [],
      registroContato: {
        id: null,
        respostaContato: {
            id: null,
            descricao: "",
            dataCadastro: ""
        },
        dataCadastro: "",
        observacao: ""
      },
      selectedRegistroContatos: [],
      errors: [],
      titulo: "Pré-Atendimento",
      confirm: {
        title: "Atenção",
        contentHtml:
          "Tem certeza que deseja apagar registro(s) selecionada(s).",
        ok: "Sim",
        cancel: "Não",
      },
      rules: {
        campoObrigatorio: [(v) => !!v || "Campo é obrigatório"],
        textoObrigatorio: [
          (v) => !!v || "Campo é obrigatório",
          (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        ],
        emailObrigatorio: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        ],
        selecaoObrigatoria: [(v) => v.length > 0 || "Seleção Obrigatória"],
      },
      dialog: false,
      dialogDelete: false,
      oldMotivoCancelamento: null,
      dialogStatusPendente: false,
      dialogStatusConfirmado: false,
      dialogMotivoCancelamento: false,
      textFieldProps: {
        appendIcon: "event",
      },
      dateProps: {
        headerColor: "blue",
      },
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
      },
      snackbar: false,
      tipoAlerta: "",
      timeoutAlerta: 6000,
      textAlerta: "",
      headersRegistroContato: [
        {
          text: "Data",
          align: "left",
          sortable: true,
          value: "dataCadastro",
        },
        {
          text: "Resposta Contato",
          align: "left",
          sortable: true,
          value: "respostaContato.descricao",
        },
        {
          text: "Observacao",
          align: "left",
          sortable: true,
          value: "observacao",
        },
      ],
    };
  },
  components: { Alerta },
  computed: {
    ...mapGetters(["ultimaTela"]),
    formTitle() {
      return this.registroContato && this.registroContato.id === null
        ? "Registro de Contato"
        : "Alterar Registro de Contato";
    },
    possuiPermissaoAdmin() {
      return possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN']);
    },
    changeStatusPendenteComputed:{
      get () {
        return this.visible
      },
      set (value){
        console.log(value)

        this.changeStatusPendente()
      }
    },
    changeStatusCanceladoComputed:{
      get () {
        return this.visible
      },
      set (value){
        console.log(value)

        this.changeStatusCancelado()
      }
    },
    changeStatusConfirmadoComputed:{
      get () {
        return this.visible
      },
      set (value){
        console.log(value)

        this.changeStatusConfirmado()
      }
    }
  },
  watch: {
    loader(){
      const l = this.loader
      this[l] = !this[l]

      //setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  },
  methods: {
    ...mapActions(["setRegistroPreAtendimentoQuery", "setAlerta"]),
    visualizarImpressao(){
      this.loader = 'loadingBtnVisualizarImpressao';
      console.log(`/atendimento/download/byRegistroPreAtendimento/${this.idParametter}`)
      this.$http.get(`/atendimento/download/byRegistroPreAtendimento/${this.idParametter}`, {responseType: 'blob'})
          .then((response) => {
            this.loadingBtnVisualizarImpressao = false;
            let blob = new Blob([response.data], { type: 'application/pdf' }),
            url = this.$window.URL.createObjectURL(blob)
            this.$window.open(url)
          });
    },
    loadModel() {
      var self = this;
      console.log("RegistroPreAtendimento")
      console.log(self.isModal)
      console.log(self.idParametter)
      console.log(self.idAgendamento)
      console.log(self.idPreAtendimento)
      console.log("### PACIENTE DO AGENDAMENTO");
      console.log(this.pacientePreAtendimento);

      if (this.idParametter){ //existe pre atendimento
          this.$http
            .get(`/registroPreAtendimento/${this.idParametter}`)
            .then((response) => {
              self.showConfirmarAtendimentoBtn = true;
              self.showPendenteAtendimentoBtn = true;
              self.showCancelarAtendimentoBtn = true;
              console.log("----RESPONSE---")
              console.log(response)
              self.registroPreAtendimento = response.data;
              
              if(!this.registroPreAtendimento.registroContatos) {
                this.registroPreAtendimento.registroContatos = this.registroContatos;
              }
              console.log(self.registroPreAtendimento.status)
              console.log(self.registroPreAtendimento.status)
              console.log(self.registroPreAtendimento.status)
              switch (self.registroPreAtendimento.status) {
                case 'CONFIRMADO':
                  this.showConfirmarAtendimentoBtn = false;
                  this.showPendenteAtendimentoBtn = false;
                  this.showCancelarAtendimentoBtn = true;
                  this.showImprimirPosConsultaBtn = false;
                  break;
                case 'PENDENTE':
                  this.showConfirmarAtendimentoBtn = true;
                  this.showPendenteAtendimentoBtn = true;
                  this.showCancelarAtendimentoBtn = true;
                  this.showImprimirPosConsultaBtn = false;
                  break;
                case 'CANCELADO':
                  this.showConfirmarAtendimentoBtn = false;
                  this.showPendenteAtendimentoBtn = false;
                  this.showCancelarAtendimentoBtn = false;
                  this.showImprimirPosConsultaBtn = false;
                  break;
                default:
                  this.showConfirmarAtendimentoBtn = true;
                  this.showPendenteAtendimentoBtn = true;
                  this.showCancelarAtendimentoBtn = true;
                  this.showImprimirPosConsultaBtn = false;
                  break;
              }

              if (self.registroPreAtendimento.agendamento.statusAgendamento.id == 5){ //ATENDIDO
                this.showCancelarAtendimentoBtn = false;
                this.showImprimirPosConsultaBtn = true;
              }

              self.inativo = self.registroPreAtendimento.agendamento.unidade.situacao === false || self.registroPreAtendimento.agendamento.unidade.empresa.situacao === false;
              self.showAdicionarContatoBtn = true;
              self.showAcoesEditarRegistroContatoContatoBtn = true;
              self.showAcoesExcluirRegistroContatoContatoBtn = true;
              if (self.inativo && !isAdminMaster()){
                  self.showConfirmarAtendimentoBtn = false;
                  self.showPendenteAtendimentoBtn = false;
                  self.showCancelarAtendimentoBtn = false;
                  self.showImprimirPosConsultaBtn = true;
                  self.showAdicionarContatoBtn = false;
                  self.showAcoesEditarRegistroContatoContatoBtn = false;
                  self.showAcoesExcluirRegistroContatoContatoBtn = false;
              }

            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao carregar registro",
              });
            });
      }else{ //nao existe pre atendimento
        if (!this.isModal){
          if (this.$route.params.id) {
            this.$http
              .get(`/registroPreAtendimento/${this.$route.params.id}`)
              .then((response) => {
                self.showConfirmarAtendimentoBtn = true;
                self.showPendenteAtendimentoBtn = true;
                self.showCancelarAtendimentoBtn = true;
                console.log("----RESPONSE---")
                console.log(response)
                self.registroPreAtendimento = response.data;
                console.log(self.registroPreAtendimento.status)
                console.log(self.registroPreAtendimento.status)
                console.log(self.registroPreAtendimento.status)
                switch (self.registroPreAtendimento.status) {
                  case 'CONFIRMADO':
                    this.showConfirmarAtendimentoBtn = false;
                    this.showPendenteAtendimentoBtn = false;
                    this.showCancelarAtendimentoBtn = true;
                    this.showImprimirPosConsultaBtn = false;
                    break;
                  case 'PENDENTE':
                    this.showConfirmarAtendimentoBtn = true;
                    this.showPendenteAtendimentoBtn = true;
                    this.showCancelarAtendimentoBtn = true;
                    this.showImprimirPosConsultaBtn = false;
                    break;
                  case 'CANCELADO':
                    this.showConfirmarAtendimentoBtn = false;
                    this.showPendenteAtendimentoBtn = false;
                    this.showCancelarAtendimentoBtn = false;
                    this.showImprimirPosConsultaBtn = false;
                    break;
                  default:
                    this.showConfirmarAtendimentoBtn = true;
                    this.showPendenteAtendimentoBtn = true;
                    this.showCancelarAtendimentoBtn = true;
                    this.showImprimirPosConsultaBtn = false;
                    break;
                }
                
                if (self.registroPreAtendimento.agendamento.statusAgendamento.id == 5){ //ATENDIDO
                  this.showCancelarAtendimentoBtn = false;
                  this.showImprimirPosConsultaBtn = true;
                }

                self.inativo = self.registroPreAtendimento.agendamento.unidade.situacao === false || self.registroPreAtendimento.agendamento.unidade.empresa.situacao === false;
                self.showAdicionarContatoBtn = true;
                self.showAcoesEditarRegistroContatoContatoBtn = true;
                self.showAcoesExcluirRegistroContatoContatoBtn = true;
                if (self.inativo && !isAdminMaster()){
                    self.showConfirmarAtendimentoBtn = false;
                    self.showPendenteAtendimentoBtn = false;
                    self.showCancelarAtendimentoBtn = false;
                    self.showImprimirPosConsultaBtn = true;
                    self.showAdicionarContatoBtn = false;
                    self.showAcoesEditarRegistroContatoContatoBtn = false;
                    self.showAcoesExcluirRegistroContatoContatoBtn = false;
                }

              })
              .catch(() => {
                self.setAlerta({
                  tipoAlerta: "error",
                  textAlerta: "Erro ao carregar registro",
                });
              });
          }
        }else{
          if (self.agendamentoSelecionado != null){
            self.inativo = self.agendamentoSelecionado.unidade.situacao === false || self.agendamentoSelecionado.unidade.empresa.situacao === false;
            self.showAdicionarContatoBtn = true;
            self.showAcoesEditarRegistroContatoContatoBtn = true;
            self.showAcoesExcluirRegistroContatoContatoBtn = true;
            self.showConfirmarAtendimentoBtn = true;
            self.showPendenteAtendimentoBtn = true;
            self.showCancelarAtendimentoBtn = true;
            if (self.inativo && !isAdminMaster()){
                self.showConfirmarAtendimentoBtn = false;
                self.showPendenteAtendimentoBtn = false;
                self.showCancelarAtendimentoBtn = false;
                self.showImprimirPosConsultaBtn = true;
                self.showAdicionarContatoBtn = false;
                self.showAcoesEditarRegistroContatoContatoBtn = false;
                self.showAcoesExcluirRegistroContatoContatoBtn = false;
            }
          }
        }
      }
    },
    onSubmit() {
      var self = this;

      if (!this.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor preencher todos os campos",
        });
      } else {
        
        if (self.idAgendamento){
          self.registroPreAtendimento.agendamento = Object.assign({},{id: self.idAgendamento});
        }

        if (self.registroPreAtendimento.id) {  
          this.$http
            .put("/registroPreAtendimento", this.registroPreAtendimento)
            .then((response) => {
              if(response.data.message) {
                self.setAlerta({
                  tipoAlerta: "error",
                  textAlerta: response.data.message,
                });
              } else {
                if (!this.isModal){
                  self.setRegistroPreAtendimentoQuery({
                    query: self.registroPreAtendimento.observacao,
                  });
                }
                if (!this.isModal){
                  self.cancelar();
                }
                self.$emit('save', response.data)
                self.setAlerta({
                  tipoAlerta: "success",
                  textAlerta: "Registro Pré-Atendimento alterado com sucesso",
                });
              }
            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao alterar registro",
              });
            });
        } else {
          console.log('salvando preatendimento')
          console.log(this.registroPreAtendimento)
          console.log(JSON.stringify( this.registroPreAtendimento ))
          this.$http
            .post("/registroPreAtendimento", this.registroPreAtendimento)
            .then((response) => {
              if(response.data.message) {
                self.setAlerta({
                  tipoAlerta: "error",
                  textAlerta: response.data.message,
                });
              } else {
                if (!this.isModal){
                  self.setRegistroPreAtendimentoQuery({
                    query: self.registroPreAtendimento.observacao,
                  });
                }

                if (!this.isModal){
                  self.cancelar();
                }
                self.$emit('save', response.data)
                self.setAlerta({
                  tipoAlerta: "success",
                  textAlerta: "Registro Pré-Atendimento criado com sucesso",
                });
              }
            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao criar registro",
              });
            });
        }
      }
    },
    buscarMedico() {
      var self = this;

      let url = self.agendamentoUnidadeId ? `/perfil?authority=ROLE_MEDICO_ATENDIMENTO&unidadeId=${self.agendamentoUnidadeId}&max=-1` : `/perfil?authority=ROLE_MEDICO_ATENDIMENTO&max=-1`;
      console.log(url);
      this.$http
        .get(url)
        .then((response) => {
          this.medicos = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos Atendentes",
          });
        });
    },
    buscarRespostaContato() {
      var self = this;
      this.$http
        .get(`/respostaContato?max=-1`)
        .then((response) => {
          this.respostaContatos = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Respota Contato",
          });
        });
    },
    buscarMotivoCancelamento() {
      var self = this;
      this.$http
        .get(`/motivoCancelamento?max=-1`)
        .then((response) => {
          this.motivosCancelamentos = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Respota Contato",
          });
        });
    },
    itemCreateEdit(item) {
      this.dialog = true;
      this.registroContato = item;
      this.registroContato.dataCadastro = moment(moment.now()).format("YYYY-MM-DDTHH:mm:ss");
    },
    confirmCreateEdit() {
      if(this.registroPreAtendimento.registroContatos) {
        if(this.registroPreAtendimento.registroContatos.indexOf(this.registroContato) > -1) {
          Object.assign(this.registroPreAtendimento.registroContatos[this.registroPreAtendimento.registroContatos.indexOf(this.registroContato)], this.registroContato);
        } else {
          this.registroPreAtendimento.registroContatos.push(this.registroContato);
        }
      } else {
        this.registroPreAtendimento.registroContatos = this.registroContatos;
        this.registroPreAtendimento.registroContatos.push(this.registroContato);
      }
      this.cancelCreateEdit();
    },
    cancelCreateEdit() {
      this.registroContato = Object.assign({}, null);
      this.dialog = false;
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.registroContato = item;
    },
    confirmDelete() {
      this.registroPreAtendimento.registroContatos =
        this.registroPreAtendimento.registroContatos.filter(
          (v) => v != this.registroContato
        );
      this.cancelDelete();
    },
    cancelDelete() {
      this.registroContato = Object.assign({}, null);
      this.dialogDelete = false;
    },
    changeStatusCancelado() {
      let newStatus = "CANCELADO";

      this.oldStatus = this.registroPreAtendimento.status;
      this.registroPreAtendimento.status = newStatus;
      this.registroPreAtendimento.agendamento.id = this.idAgendamento;
      this.registroPreAtendimento.agendamento.statusAgendamento = this.makeStatusObj(newStatus);
      this.oldMotivoCancelamento = this.registroPreAtendimento.motivoCancelamento;
      this.dialogMotivoCancelamento = true;      
    },    
    confirmMotivoCancelamento() {
      if(!this.registroPreAtendimento.motivoCancelamento) {
        this.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Favor informar um motivo de cancelamento",
        });
      } else {
        this.dialogMotivoCancelamento = false;
        this.onSubmit();
      }
    },
    cancelMotivoCancelamento() {
      this.registroPreAtendimento.motivoCancelamento = this.oldMotivoCancelamento;
      this.registroPreAtendimento.status = this.oldStatus;
      this.dialogMotivoCancelamento = false;
    },    
    changeStatusPendente() {
      let newStatus = "PENDENTE";

      if(this.registroPreAtendimento.status != 'PENDENTE') {
        this.oldStatus = this.registroPreAtendimento.status;
        this.registroPreAtendimento.status = newStatus;
        this.registroPreAtendimento.agendamento.id = this.idAgendamento;
        this.registroPreAtendimento.agendamento.statusAgendamento = this.makeStatusObj(newStatus);
        this.dialogStatusPendente = true;
      } else {
        this.onSubmit();
      }
    },
    confirmStatusPendente() {
      this.oldStatus = null;
      this.dialogStatusPendente = false;
      this.registroPreAtendimento.motivoCancelamento = null; // Apaga o motivo de cancelamento ao aterar o status para não cancelado
      this.onSubmit();
    },
    cancelStatusPendente() {
      this.registroPreAtendimento.status = this.oldStatus;
      this.oldStatus = null;
      this.dialogStatusPendente = false;
    },
    changeStatusConfirmado() {
      let newStatus = "CONFIRMADO";

      this.oldStatus = this.registroPreAtendimento.status;
      this.registroPreAtendimento.status = newStatus;
      this.registroPreAtendimento.agendamento.id = this.idAgendamento;
      this.registroPreAtendimento.agendamento.statusAgendamento = this.makeStatusObj(newStatus);
      this.dialogStatusConfirmado = true;
    },
    confirmStatusConfirmado() {
      if(this.registroPreAtendimento.medico) {
        this.oldStatus = null;
        this.dialogStatusConfirmado = false;
        this.registroPreAtendimento.motivoCancelamento = null; // Apaga o motivo de cancelamento ao aterar o status para não cancelado
        this.onSubmit();
      } else {
        this.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Favor informar o médico responsável antes de confirmar o atendimento.",
        });
        this.cancelStatusConfirmado();
      }
    },
    cancelStatusConfirmado() {
      this.registroPreAtendimento.status = this.oldStatus;
      this.oldStatus = null;
      this.dialogStatusConfirmado = false;
    },
    formatDataNascimento(dataNascimento) {
      return moment(dataNascimento, "YYYY-MM-DD").format("DD/MM/YYYY")
    },
    cancelar() {
      this.$emit('cancel');
      if (!this.isModal){
        if (this.ultimaTela) {
          this.$router.push({ path: this.ultimaTela });
        } else {
          this.$router.go(-1);
        }
      }
    },
    makeStatusObj(status) {
        return status == "PENDENTE" ? {id:2,descricao:'PENDENTE'} : 
                status == "CANCELADO" ? {id:4,descricao:'CANCELADO'} : 
                status == "CONFIRMADO" ? {id:3,descricao:'CONFIRMADO'} : 
                {id:1,descricao:'AGENDADO'};
    },
  },
  created() {
    if(this.possuiPermissaoAdmin) {
      this.headersRegistroContato.push({ text: "Ações", value: "actions", sortable: false })
    }
    this.buscarMedico();
    this.buscarRespostaContato();
    this.buscarMotivoCancelamento();
    this.loadModel();
    console.log("333333333333333333333333333333333333333333333333333")
    console.log(this.idParametter)
    console.log(this.idAgendamento)
    console.log("333333333333333333333333333333333333333333333333333")
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
::v-deep #patient-data .expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 24px 0 0;
}
</style>
